import React, { useState, useRef, useEffect } from "react";
import btn_scan from "../assets/btn_scan.svg";

interface Props {
  onSubmit: (img: string) => void;
  isOpen: boolean;
}

const PopupChupHinh: React.FC<Props> = ({ isOpen, onSubmit }) => {
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when the popup is opened
  useEffect(() => {
    if (isOpen) {
      setCapturedImage(null);
      setIsLoading(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCamera = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCapturedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onClickCheckin = () => {
    if (capturedImage) {
      setIsLoading(true);
      onSubmit(capturedImage);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col justify-center items-center px-20 py-64 mt-0 w-full text-base bg-black bg-opacity-50 max-md:px-5 max-md:py-24 max-md:mt-0 max-md:max-w-full">
      <div className="flex flex-col p-8 mb-0 max-w-full bg-pink-50 rounded-2xl w-[822px] max-md:px-5 max-md:mb-2.5">
        <div className="w-full text-base md:text-xl font-semibold text-red-600 capitalize max-md:max-w-full text-center">
          Chụp ảnh xác nhận check-in
          <br />
          Chụp khách với combo kit
        </div>
        <div className="flex justify-center mt-2 md:mt-8 w-full max-md:max-w-full text-sm md:text-base">
          {capturedImage ? (
            <div
              onClick={handleCamera}
              className="flex justify-center items-center self-center mt-4 bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border border-[#E12022] overflow-hidden w-[250px]"
            >
              <img
                loading="lazy"
                src={capturedImage}
                className="w-full object-cover"
              />
            </div>
          ) : (
            <div
              onClick={handleCamera}
              className="flex justify-center items-center self-center px-4 mt-4 bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border-dashed border-[#E12022] aspect-square w-[250px]"
            >
              <img
                loading="lazy"
                src={btn_scan}
                className="w-full aspect-square"
              />
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
            capture="environment"
            className="hidden"
          />
        </div>
        {isLoading ? (
          <button className="gap-2.5 self-stretch px-4 py-3 mt-3 w-full font-semibold text-white bg-red-600 rounded-[10000px] max-md:max-w-full cursor-pointer text-center">
            Loading...
          </button>
        ) : (
          <button
            onClick={onClickCheckin}
            className="gap-2.5 self-stretch px-4 py-3 mt-3 w-full font-semibold text-white bg-red-600 rounded-[10000px] max-md:max-w-full cursor-pointer text-center"
            disabled={!capturedImage}
          >
            Xác nhận thông tin và Check-in
          </button>
        )}
      </div>
    </div>
  );
};

export default PopupChupHinh;
