import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import logo from "../assets/logo.png";
import btn_scan from "../assets/btn_scan.svg";
import bg_1 from "../assets/bg_1.png";

import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { Scanner } from "@yudiel/react-qr-scanner";
import Api from "../components/api/api";
import { URL_API } from "../components/constants/api";
import ToastProvider from "../hooks/useToastProvider";
import PopupChupHinh from "../components/PopupChupHinh";
import PopupThanks from "../components/PopupThanks";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [isScan, setIsScan] = useState(false);
  const [textScan, setTextScan] = useState("");
  const [dataScan, setDataScan] = useState<any | null>(null);
  const [dataCheckIn, setDataCheckin] = useState<any | null>(null);
  const [isOpenPopupChupHinh, setIsOpenPopupChupHinh] = useState(false);

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    console.log("data", dataUser);
  }, [dataUser]);

  const handleScan = () => {
    setIsScan(true);
  };

  const onResultScan = (result: any) => {
    console.log(result);
    if (result) {
      if (result[0].format == "qr_code") {
        console.log("Code", result[0].rawValue);
        if (textScan === result[0].rawValue) return;
        setTextScan(result[0].rawValue);
      }
    }
  };

  useEffect(() => {
    if (textScan != "") {
      checkCode(textScan);
    }
  }, [textScan]);

  const checkCode = async (code: string) => {
    const params = {
      code_id: code,
      pg_id: dataUser.id,
    };
    const res: any = await Api.post(URL_API.SCAN, params);
    console.log("handleSubmitForm", res, res.data);
    if (res.data.status == false) {
      ToastProvider({ type: "success", content: res.data.message });
      setDataScan(res.data);
    } else {
      setDataScan(res.data);
    }
  };

  const handleScanAgain = () => {
    setDataScan(null);
    setTextScan("");
    setIsScan(false);
  };

  const handleCheckin = () => {
    setIsOpenPopupChupHinh(true);
  };
  const onUpdateHinhChup = async (img: string) => {
    console.log("img", img);
    const params = {
      code_id: dataScan.info.code_id,
      pg_id: dataUser.id,
      img: img,
    };
    const res: any = await Api.post(URL_API.CHECK_IN, params);
    console.log("onUpdateHinhChup", res, res.data);
    if (res.data.status == false) {
      ToastProvider({ type: "error", content: res.data.message });
    } else {
      setIsOpenPopupChupHinh(false);
      setDataCheckin(res.data);
    }
  };
  const onLogout = () => {
    dispatch(logout());
  };
  const onDoneRound = () => {
    console.log("onDoneRound");
    resetPopupState(); // Reset state before opening the popup
    setDataCheckin(null);
    setDataScan(null);
    setTextScan("");
    setIsScan(false);
  };

  const resetPopupState = () => {
    setIsOpenPopupChupHinh(false);
    setDataScan(null);
    setDataCheckin(null);
    setTextScan("");
  };

  return (
    <>
      <div
        className={`w-full h-full min-h-screen bg-no-repeat bg-bottom bg-cover`}
        style={{ backgroundImage: `url(${bg_1})` }}
      >
        <div className="flex flex-col justify-center px-5 py-16 mx-auto w-full text-base font-bold max-w-[480px] text-neutral-80">
          <img loading="lazy" src={logo} className="w-full" />
          {dataScan ? (
            <>
              {dataScan.status == false ? (
                <>
                  <div className="mt-20 px-3 py-3 w-full text-xl font-semibold bg-[#0D3C86] backdrop-opacity-60 rounded-2xl border border-solid border-[#0D3C86]">
                    <span className="font-bold flex gap-4 justify-center text-[16px] text-center text-white">
                      {dataScan.message}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-8 flex flex-col px-6 py-4 font-semibold text-black bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border-dashed border-[#E12022]">
                    <div className="text-xl font-black uppercase">
                      Thông tin khách hàng
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Họ và Tên:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.name}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Điện Thoại:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.phone}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Email:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex flex-col px-6 py-4 font-semibold text-black bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border-dashed border-[#E12022]">
                    <div className="text-xl font-black uppercase">
                      Thông tin vé
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Loại Vé:</div>
                      <div className="flex-1 shrink font-grotters font-bold basis-0 text-[#E12022]">
                        {dataScan.info.ticket}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Mã vé:</div>
                      <div className="flex-1 shrink font-bold basis-0 text-sm">
                        #{dataScan.info.code_id}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Đơn hàng:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        #{dataScan.info.order_id}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Combo kit:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.combo_kit}
                      </div>
                    </div>
                  </div>
                  {dataScan.info.is_checkin == 1 ? (
                    <>
                      <div className="mt-4 flex flex-col px-6 py-4 items-center font-semibold text-black bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border-dashed border-[#E12022]">
                        <div className="text-xl font-black uppercase">
                          ĐÃ CHECK-IN
                        </div>
                        <div className="mt-4 w-full text-[#E12022] text-center">
                          {dataScan.message}
                        </div>
                        {dataScan.info.hinh_checkin ? (
                          <>
                            <div className="mt-4 w-full text-[#E12022]">
                              <img
                                className="w-full border-4 border-gray-300 rounded-lg"
                                src={dataScan.info.hinh_checkin}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {dataScan.info.is_checkin == 1 ? (
                <Button
                  isLoading={false}
                  label="Xác nhận"
                  handleClick={handleScanAgain}
                />
              ) : (
                <>
                  <Button
                    isLoading={false}
                    label="Check-in"
                    handleClick={handleCheckin}
                  />
                </>
              )}
            </>
          ) : isScan ? (
            <>
              <div className="flex flex-col justify-center p-4 mt-[30px] w-full bg-indigo-50 rounded-2xl border border-solid border-stone-300">
                <div className=" w-full min-h-[300px]">
                  <Scanner onScan={onResultScan} />
                </div>
              </div>
              <Button
                isLoading={false}
                label="Trở Lại"
                handleClick={handleScanAgain}
              />
            </>
          ) : (
            <>
              <div className="flex z-0 gap-10 justify-center items-start mt-8 w-full text-base font-bold text-white">
                <div
                  onClick={onLogout}
                  className="gap-4 self-stretch p-2 bg-[#FFFFFF] backdrop-opacity-80 text-black rounded-lg border border-[#B9BBC6]"
                >
                  {dataUser.name}
                </div>
              </div>
              <div
                onClick={handleScan}
                className="flex justify-center items-center self-center px-4 mt-12 bg-[#fbf5f9] backdrop-opacity-60 rounded-2xl border-2 border-dashed border-[#E12022] aspect-square w-[250px]"
              >
                <img
                  loading="lazy"
                  src={btn_scan}
                  className="w-full aspect-square"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <PopupChupHinh isOpen={isOpenPopupChupHinh} onSubmit={onUpdateHinhChup} />
      <PopupThanks data={dataCheckIn} onDone={onDoneRound} />
    </>
  );
}

export default Home;
