import React, { useState, useRef } from "react";
import check_in_done from "../assets/check-in-done.png";

interface Props {
  data: any;
  onDone: () => void;
}

const PopupThanks: React.FC<Props> = ({ data, onDone }) => {
  if (data == null) return null;

  const onClickCheckin = () => {
    onDone();
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col justify-center items-center px-20 py-64 mt-0 w-full text-base bg-black bg-opacity-50 max-md:px-5 max-md:py-24 max-md:mt-0 max-md:max-w-full">
      <div className="flex flex-col p-8 mb-0 max-w-full bg-pink-50 rounded-2xl w-[822px] max-md:px-5 max-md:mb-2.5">
        <div className="w-full text-xl font-semibold text-red-600 capitalize max-md:max-w-full text-center">
          <img
            loading="lazy"
            src={check_in_done}
            className="w-[100px] aspect-square mx-auto"
          />
          Check-in thành công
        </div>
        <button
          onClick={onClickCheckin}
          className="gap-2.5 self-stretch px-4 py-3 mt-3 w-full font-semibold text-white bg-red-600 rounded-[10000px] max-md:max-w-full cursor-pointer text-center"
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default PopupThanks;
